import { createContext, useContext, useEffect, useState } from "react";

type CallContextType = {
  callState: "incoming" | "ongoing" | "idle";
  setCallState: (value: "incoming" | "ongoing" | "idle") => void;
  formatedCallDuration: string;
};

const CallContext = createContext<CallContextType | undefined>(undefined);

export const CallProvider = ({ children }: { children: React.ReactNode }) => {
  const [callState, setCallState] =
    useState<CallContextType["callState"]>("idle");
  const [callDuration, setCallDuration] = useState<number>(0);
  const formatedCallDuration = `${Math.floor(callDuration / 60)}:${(
    "00" + Math.floor(callDuration % 60)
  ).slice(-2)}`;

  useEffect(() => {
    if (callState === "ongoing") {
      const interval = setInterval(() => {
        setCallDuration((prev) => prev + 1);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setCallDuration(0);
    }
  }, [callState]);

  return (
    <CallContext.Provider
      value={{ callState, setCallState, formatedCallDuration }}
    >
      {children}
    </CallContext.Provider>
  );
};

const useCall = () => {
  const context = useContext(CallContext);
  if (context === undefined) {
    throw new Error("useCall must be used within a CallProvider");
  }
  return context;
};

export default useCall;
