import React, { useEffect } from "react";

type TimerContextType = {
  mm: `${number}${number}`;
  ss: `${number}${number}`;
  paused: boolean;
  setPause: (value?: boolean) => void;
};

const TimerContext = React.createContext<TimerContextType | undefined>(
  undefined
);

export const TimerProvider = ({ children }: { children: React.ReactNode }) => {
  const [minutes, setMinutes] = React.useState<number>(15);
  const [seconds, setSeconds] = React.useState<number>(0);
  const [paused, setPaused] = React.useState<boolean>(true);

  const mm = `${minutes < 10 ? "0" : ""}${minutes}` as TimerContextType["mm"];
  const ss = `${seconds < 10 ? "0" : ""}${seconds}` as TimerContextType["ss"];

  const setPause = (value?: boolean) => {
    setPaused(value ?? !paused);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (paused) return;
      setSeconds((prev) => prev - 1);
      if (seconds === 0) {
        setMinutes((prev) => prev - 1);
        setSeconds(59);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [paused, seconds]);

  return (
    <TimerContext.Provider value={{ mm, ss, paused, setPause }}>
      {children}
    </TimerContext.Provider>
  );
};

const useTimer = () => {
  const context = React.useContext(TimerContext);
  if (context === undefined) {
    throw new Error("useTimer must be used within a TimerProvider");
  }
  return context;
};

export default useTimer;
