import { motion } from "framer-motion";
import { CircularProgressbar } from "react-circular-progressbar";
import { useGlobalMetrics } from "../../../contexts/GlobalMetrics";
import { useIslandState } from "../../../contexts/IslandState";
import useCall from "../../../contexts/Call";

const NotchCallNotchTimer = () => {
  const { statusBarFontSize, dynamicIslandHeight } = useGlobalMetrics();
  const { islandVariant } = useIslandState();
  const { formatedCallDuration } = useCall();
  return (
    <motion.div
      className="w-full h-full flex items-center justify-between text-white scale-100"
      initial={{
        filter: `blur(${statusBarFontSize * 0.5}px)`,
      }}
      animate={{
        filter: `blur(0px)`,
      }}
      transition={{
        type: "spring",
        damping: 13.2,
        stiffness: 130,
      }}
      exit={{
        filter: `blur(${statusBarFontSize * 0.5}px)`,
      }}
    >
      <motion.div
        className="flex h-full items-center"
        style={{
          paddingLeft: `${statusBarFontSize * 0.45}px`,
        }}
        initial={{
          opacity: 0,
          filter: `blur(${statusBarFontSize * 0.5}px)`,
          x: `${statusBarFontSize * 2}px`,
        }}
        animate={{
          opacity: 1,
          filter: `blur(0px)`,
          x: "0px",
        }}
        transition={{
          type: "spring",
          damping: 13.2,
          stiffness: 130,
          duration: 20,
        }}
        exit={{
          opacity: 0,
          filter: `blur(${statusBarFontSize * 0.5}px)`,
          x: `${statusBarFontSize * 2}px`,
        }}
      >
        <motion.img
          src="/icons/Phone.svg"
          initial={{
            transform: "rotate(240deg)",
            height: 0,
            filter: `brightness(0) saturate(100%) invert(30%) sepia(43%) saturate(601%) hue-rotate(77deg) brightness(96%) contrast(88%)`,
          }}
          animate={{
            transform: `rotate(240deg)`,
            height: `${statusBarFontSize * 0.36}px`,
            filter: `brightness(100) saturate(100%) invert(30%) sepia(43%) saturate(601%) hue-rotate(77deg) brightness(96%) contrast(88%)`,
          }}
        />
        <span
          className="font-medium"
          style={{
            color: "#57d76a",
            marginLeft: `${statusBarFontSize * 0.1}px`,
            fontSize: `${statusBarFontSize * 0.64}px`,
          }}
        >
          {formatedCallDuration}
        </span>
      </motion.div>
      <motion.div
        className="rounded-full flex items-center justify-center overflow-hidden"
        style={{
          height: `${dynamicIslandHeight}px`,
          width: `${dynamicIslandHeight}px`,
        }}
        initial={{
          x: `-${dynamicIslandHeight}px`,
          opacity: 0,
        }}
        animate={{
          x: "0px",
          opacity: 1,
        }}
        exit={{
          x: `-${dynamicIslandHeight}px`,
          opacity: 0,
        }}
        transition={{
          delay: islandVariant === "min_dual" ? 0.25 : 0,
          type: "spring",
          damping: 13.2,
          stiffness: 130,
        }}
      >
        <motion.div
          className="h-[56%] w-[56%]"
          initial={{ x: `-${dynamicIslandHeight * 4}px` }}
          animate={{ x: "0px" }}
          exit={{ x: `-${dynamicIslandHeight * 4}px` }}
          transition={{
            // delay: islandVariant === "min_dual" ? 0.25 : 0,

            type: "spring",
            damping: 9,
            stiffness: 130,
          }}
        >
          <CircularProgressbar
            value={90}
            strokeWidth={13}
            styles={{
              path: {
                stroke: "#fe9f0f",
                strokeLinecap: "round",
              },
            }}
          />
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default NotchCallNotchTimer;
