import React from "react";
import { motion } from "framer-motion";
import { useGlobalMetrics } from "../../contexts/GlobalMetrics";
import { IslandVariant } from "../../contexts/IslandState";

const SecondaryNotch = ({
  islandVariant,
}: {
  islandVariant: IslandVariant;
}) => {
  const { statusBarFontSize, dynamicIslandHeight, screenWidth, bezel } =
    useGlobalMetrics();

  const secondaryNotchAnimationVariants: Record<
    IslandVariant,
    { [key: string]: any }
  > = {
    idle: {},
    min_single: {},
    min_dual: {},
    max: {
      x: `-${dynamicIslandHeight}px`,
    },
  };
  return (
    <motion.div
      className="absolute right-0 h-full aspect-square bg-black rounded-full"
      animate={secondaryNotchAnimationVariants[islandVariant]}
      transition={{
        delay: islandVariant === "min_dual" ? 0.25 : 0,
        duration: 2,
        type: "spring",
        damping: 13.2,
        stiffness: 130,
      }}
    ></motion.div>
  );
};

export default SecondaryNotch;
