import React, { useEffect, useState } from "react";
import { useGlobalMetrics } from "../../contexts/GlobalMetrics";
import {
  AnimatePresence,
  motion,
  useAnimate,
  useAnimationControls,
  useMotionValue,
  useSpring,
  useTransform,
} from "framer-motion";
import { IslandVariant, useIslandState } from "../../contexts/IslandState";
import IncomingCall from "./States/call";
import Timer from "./States/timer";
import Goo from "../../components/Goo";
import PrimaryNotch from "./PrimaryNotch";
import SecondaryNotch from "./SecondaryNotch";
import { CircularProgressbar } from "react-circular-progressbar";
import NotchCallNotchTimer from "./States/notch-call-notch-timer";
import useCall from "../../contexts/Call";
import useSimultaneousAnimation from "../../hooks/useSimultaneousAnimation";

const DynamicIsland = () => {
  const { statusBarFontSize, dynamicIslandHeight, screenWidth, bezel } =
    useGlobalMetrics();

  const { islandState, setIslandState, islandVariant } = useIslandState();
  const { callState } = useCall();

  const dynamicIslandAnimationVariants: Record<
    IslandVariant,
    { [key: string]: any }
  > = {
    idle: {
      height: `${dynamicIslandHeight}px`,
      width: `${screenWidth * 0.28}px`,
      translateY: `${-statusBarFontSize * 0.85}px`,
      translateX: "-50%",
    },
    min_single: {
      height: `${dynamicIslandHeight}px`,
      width: `${screenWidth * 0.28}px`,
      translateY: `${-statusBarFontSize * 0.85}px`,
      translateX: "-50%",
    },
    min_dual: {
      height: `${dynamicIslandHeight}px`,
      width: `${screenWidth * 0.5}px`,
      translateY: `${-statusBarFontSize * 0.85}px`,
      translateX: "-50%",
    },
    max: {
      height: `${statusBarFontSize * 4}px`,
      width: `${screenWidth - 4 * bezel}px`,
      translateY: `${-statusBarFontSize}px`,
    },
  };

  const initialDynamicIslandAnimationVariant =
    dynamicIslandAnimationVariants["idle"];

  const dynamicIslandAnimationVariant =
    dynamicIslandAnimationVariants[islandVariant];

  const { animate, controls } = useSimultaneousAnimation(
    dynamicIslandAnimationVariant,
    [
      {
        ...dynamicIslandAnimationVariants["idle"],
        // height: `${dynamicIslandAnimationVariants.max.height}px`,
        transition: {
          duration: 0.2,
          ease: "easeIn",
        },
      },
      {
        ...dynamicIslandAnimationVariants["max"],
        transition: {
          duration: 0.2,
          ease: "easeOut",
        },
      },
    ]
  );

  useEffect(() => {
    if (callState === "ongoing") {
      animate();
    }
  }, [callState]);

  const springValue = useMotionValue(screenWidth * 0.0075);
  const springGooBlurValue = useSpring(springValue, { duration: 1000 });

  useEffect(() => {
    if (islandVariant === "min_dual") {
      springGooBlurValue.set(screenWidth * 0.0075);
    } else {
      springGooBlurValue.set(screenWidth * 0.0275);
    }
  }, [islandVariant, springGooBlurValue]);

  const [displayValue, setDisplayValue] = React.useState(0);
  useEffect(() => {
    return springGooBlurValue.on("change", (latest) => {
      setDisplayValue(latest);
    });
  }, [springGooBlurValue]);

  return (
    <motion.div
      className="absolute left-[50%] top-[50%] z-50"
      initial={initialDynamicIslandAnimationVariant}
      animate={
        islandState === "call" ? controls : dynamicIslandAnimationVariant
      }
      exit={initialDynamicIslandAnimationVariant}
      transition={{
        duration: 1,
        type: "spring",
        damping: 13.2,
        stiffness: 130,
      }}
      onClick={() => {
        // setIslandState((prev) =>
        //   prev === "notch:call,notch:timer" ? "idle" : "notch:call,notch:timer"
        // );
      }}
    >
      <Goo
        className="absolute inset-0 rounded-full"
        blur={displayValue}
        composite={islandVariant !== "min_dual"}
      >
        <AnimatePresence>
          {/* Primary Notch */}

          <PrimaryNotch islandVariant={islandVariant} key={"primary-notch"} />
          {/* Secondary Notch */}
          <SecondaryNotch
            islandVariant={islandVariant}
            key={"secondary-notch"}
          />
        </AnimatePresence>
      </Goo>

      {/* Notch Contents: */}
      <AnimatePresence>
        {islandState === "call" ? (
          <IncomingCall />
        ) : islandState === "timer" ? (
          <Timer />
        ) : islandState === "notch:call,notch:timer" ? (
          <NotchCallNotchTimer />
        ) : null}
      </AnimatePresence>
    </motion.div>
  );
};

export default DynamicIsland;
