// All thanks to https://github.com/luukdv/gooey-react
// This code is copied from the above mentioned repository, and modified here for the use case.

import React from "react";

const Goo = ({
  children,
  className,
  composite = false,
  id = "gooey-react",
  style,
  blur = 8,
}: {
  children: React.ReactNode;
  className?: string;
  composite?: boolean;
  id?: string;
  style?: React.CSSProperties;
  blur?: number;
}) => {
  const alpha = blur * 6;
  const shift = alpha / -2;
  const r = "1 0 0 0 0";
  const g = "0 1 0 0 0";
  const b = "0 0 1 0 0";
  const a = `0 0 0 ${alpha} ${shift}`;

  return (
    <>
      <svg
        data-testid="svg"
        style={{
          pointerEvents: "none",
          position: "absolute",
        }}
      >
        <defs>
          <filter colorInterpolationFilters="sRGB" data-testid="filter" id={id}>
            <feGaussianBlur data-testid="blur" stdDeviation={blur} />
            <feColorMatrix values={`${r} ${g} ${b} ${a}`} />
            {composite && (
              <feComposite
                data-testid="composite"
                in="SourceGraphic"
              ></feComposite>
            )}
          </filter>
        </defs>
      </svg>
      <div
        className={className}
        data-testid="element"
        style={{
          ...style,
          filter: `url(#${id})`,
        }}
      >
        {children}
      </div>
    </>
  );
};

export default Goo;
