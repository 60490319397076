import React from "react";

export type IslandState =
  | "idle"
  | "call"
  | "timer"
  | "notch:call"
  | "notch:timer"
  | "call,notch:timer"
  | "timer,notch:call"
  | "notch:call,notch:timer";

export type IslandVariant = "idle" | "max" | "min_single" | "min_dual";

type IslandStateContextType = {
  islandState: IslandState;
  setIslandState: React.Dispatch<React.SetStateAction<IslandState>>;
  islandVariant: IslandVariant;
};

const IslandStateContext = React.createContext<
  IslandStateContextType | undefined
>(undefined);

export const IslandStateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [islandState, setIslandState] = React.useState<IslandState>("idle");

  const getIslandVariant = (): IslandVariant => {
    if (islandState === "idle") {
      return "idle";
    }
    if (islandState.includes("notch")) {
      var count = (islandState.match(/notch/g) || []).length;
      if (islandState.includes(",")) {
        if (count === 1) {
          return "max";
        }
        return "min_dual";
      }
      return "min_single";
    }
    return "max";
  };

  const islandVariant = getIslandVariant();

  return (
    <IslandStateContext.Provider
      value={{ islandState, setIslandState, islandVariant }}
    >
      {children}
    </IslandStateContext.Provider>
  );
};

export const useIslandState = () => {
  const context = React.useContext(IslandStateContext);
  if (context === undefined) {
    throw new Error("useIslandState must be used within a IslandStateProvider");
  }
  return context;
};
