import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useGlobalMetrics } from "../../../contexts/GlobalMetrics";
import useTimer from "../../../contexts/Timer";

const StopWatch = () => {
  const { statusBarFontSize, bezel } = useGlobalMetrics();

  const { mm, ss, paused, setPause } = useTimer();
  setPause(false);
  return (
    <motion.div
      className="absolute top-0 left-0 w-full h-full flex items-center justify-between text-white rounded-full overflow-hidden"
      style={{
        padding: `${statusBarFontSize * 0.75}px`,
        gap: `${statusBarFontSize * 0.5}px`,
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="h-full flex items-center" style={{ gap: "0.5rem" }}>
        <button className="h-full aspect-square rounded-full flex items-center justify-center bg-[#4c2e07]">
          <motion.img
            src="/icons/Pause.svg"
            initial={{ height: 0 }}
            animate={{
              height: `${statusBarFontSize * 1.4}px`,
            }}
            style={{
              filter:
                "brightness(0) saturate(100%) invert(75%) sepia(45%) saturate(4214%) hue-rotate(352deg) brightness(101%) contrast(99%)",
            }}
          />
        </button>
        <button className="h-full aspect-square rounded-full flex items-center justify-center bg-[#373337]">
          <motion.img
            src="/icons/Cross.svg"
            initial={{ height: 0 }}
            animate={{
              height: `${statusBarFontSize * 2}px`,
            }}
            style={{
              filter: "invert(100%)",
            }}
          />
        </button>
      </div>
      <div
        className="flex items-center h-full"
        style={{
          fontSize: `${statusBarFontSize * 0.9}px`,
        }}
      >
        <div className="text-[#fe9f0f] self-end font-medium">Timer</div>
        <div
          className="text-[#fe9f0f] font-thin"
          style={{
            fontSize: `300%`,
            margin: `0 ${statusBarFontSize * 0.25}px`,
          }}
        >
          <AnimatePresence mode="popLayout">
            {mm.split("").map((digit, index) => {
              return (
                <motion.span
                  key={`mm-${digit}-${index}`}
                  className="inline-flex items-center justify-center origin-top"
                  initial={{
                    opacity: 0,
                    transform: `scale(0.6) translateY(${
                      statusBarFontSize * 2
                    }px)`,
                    filter: `blur(${statusBarFontSize * 0.4}px)`,
                  }}
                  animate={{
                    opacity: 1,
                    transform: `scale(1) translateY(0px)`,
                    filter: `blur(0px)`,
                  }}
                  exit={{
                    opacity: 0,
                    transform: `scale(0.6) translateY(-${statusBarFontSize}px)`,
                    filter: `blur(${statusBarFontSize * 0.4}px)`,
                  }}
                  transition={{
                    duration: 0.5,
                    delay: (3 - index) * 0.1,
                  }}
                  style={{
                    width: `${statusBarFontSize * 1.5}px`,
                  }}
                >
                  {digit}
                </motion.span>
              );
            })}
          </AnimatePresence>
          :
          <AnimatePresence mode="popLayout">
            {ss.split("").map((digit, index) => {
              return (
                <motion.span
                  key={`ss-${digit}-${index}`}
                  className="inline-flex items-center justify-center origin-top"
                  initial={{
                    opacity: 0,
                    transform: `scale(0.6) translateY(${
                      statusBarFontSize * 2
                    }px)`,
                    filter: `blur(${statusBarFontSize * 0.4}px)`,
                  }}
                  animate={{
                    opacity: 1,
                    transform: `scale(1) translateY(0px)`,
                    filter: `blur(0px)`,
                  }}
                  exit={{
                    opacity: 0,
                    transform: `scale(0.6) translateY(-${statusBarFontSize}px)`,
                    filter: `blur(${statusBarFontSize * 0.4}px)`,
                  }}
                  transition={{
                    duration: 0.5,
                    delay: (1 - index) * 0.1,
                  }}
                  style={{
                    width: `${statusBarFontSize * 1.5}px`,
                  }}
                >
                  {digit}
                </motion.span>
              );
            })}
          </AnimatePresence>
        </div>
        {/* <div
          className="text-[#fe9f0f] self-start"
          style={{
            fontSize: `80%`,
          }}
        >
          00
        </div> */}
      </div>
    </motion.div>
  );
};

export default StopWatch;
