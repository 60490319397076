import React, { useEffect, useMemo } from "react";
import { motion } from "framer-motion";
import { useGlobalMetrics } from "../../contexts/GlobalMetrics";
import { IslandVariant } from "../../contexts/IslandState";
import useCall from "../../contexts/Call";
import useSimultaneousAnimation from "../../hooks/useSimultaneousAnimation";

const PrimaryNotch = ({ islandVariant }: { islandVariant: IslandVariant }) => {
  const { statusBarFontSize, dynamicIslandHeight, screenWidth, bezel } =
    useGlobalMetrics();
  const { callState } = useCall();

  const dynamicIslandWidths: Record<IslandVariant, number> = {
    idle: screenWidth * 0.28,
    min_single: screenWidth * 0.28,
    min_dual:
      screenWidth * 0.5 - (dynamicIslandHeight + statusBarFontSize * 0.32),
    max: screenWidth - 4 * bezel,
  };

  const primaryNotchAnimationVariants: Record<
    IslandVariant,
    { [key: string]: any }
  > = {
    idle: {},
    min_single: {},
    min_dual: {},
    max: {
      boxShadow: `0 ${bezel}px ${bezel * 2}px rgb(0 0 0 / 0.2)`,
    },
  };

  const resolvedAnimationVariants: Record<
    IslandVariant,
    { [key: string]: any }
  > = useMemo(() => {
    const obj: Partial<Record<IslandVariant, { [key: string]: any }>> = {};
    Object.keys(primaryNotchAnimationVariants).forEach((key) => {
      obj[key as IslandVariant] = {
        ...primaryNotchAnimationVariants[key as IslandVariant],
        width: `${dynamicIslandWidths[key as IslandVariant]}px`,
      };
    });
    return obj as Record<IslandVariant, { [key: string]: any }>;
  }, [islandVariant]);

  const resolvedAnimationVariant = {
    ...primaryNotchAnimationVariants[islandVariant],
    width: `${dynamicIslandWidths[islandVariant]}px`,
  };

  const { animate, controls } = useSimultaneousAnimation(
    resolvedAnimationVariant,
    [
      {
        ...resolvedAnimationVariants["idle"],
        transition: {
          duration: 0.2,
          ease: "easeIn",
        },
      },
      {
        ...resolvedAnimationVariants["max"],
        transition: {
          duration: 0.2,
          ease: "easeOut",
        },
      },
    ]
  );

  useEffect(() => {
    if (callState === "ongoing") {
      animate();
    }
  }, [callState]);

  return (
    <motion.div
      id="primary-notch"
      className="absolute top-0 bottom-0 left-0 bg-black rounded-full h-full"
      initial={{
        width: `${dynamicIslandWidths["idle"]}px`,
        ...primaryNotchAnimationVariants["idle"],
      }}
      animate={controls}
      exit={{
        width: `${dynamicIslandWidths["idle"]}px`,
        opacity: 0,
        ...primaryNotchAnimationVariants["idle"],
      }}
      transition={{
        duration: 1,
        type: "spring",
        damping: 13.2,
        stiffness: 130,
      }}
    ></motion.div>
  );
};

export default PrimaryNotch;
