import React from "react";
import { useGlobalMetrics } from "../contexts/GlobalMetrics";

const BatteryIndicator = () => {
  const { statusBarFontSize } = useGlobalMetrics();
  return (
    <div className="flex items-center">
      <div
        style={{
          height: `${statusBarFontSize * 0.88}px`,
          width: `${statusBarFontSize * 1.5}px`,
          borderRadius: `${statusBarFontSize * 0.25}px`,
        }}
        className="bg-white/60 overflow-hidden relative"
      >
        <div className="w-1/2 h-full bg-white flex"></div>
        <div className="absolute inset-0 flex items-center justify-center">
          <span
            className="text-black/80"
            style={{
              fontSize: `${statusBarFontSize * 0.72}px`,
              lineHeight: 0,
            }}
          >
            50
          </span>
        </div>
      </div>
      <div
        className="bg-white/50 right-0"
        style={{
          height: `${statusBarFontSize * 0.35}px`,
          width: `${statusBarFontSize * 0.2}px`,
          borderRadius: `0 ${statusBarFontSize}px ${statusBarFontSize}px 0`,
        }}
      ></div>
    </div>
  );
};

export default BatteryIndicator;
