import { useAnimationControls } from "framer-motion";
import { useEffect } from "react";

const useSimultaneousAnimation = (
  defaultVariant: {
    [key: string]: any;
  },
  keyframes: {
    [key: string]: any;
  }[]
) => {
  const divControls = useAnimationControls();

  const animate = async () => {
    for (const keyframe of keyframes) {
      console.log("kf");
      await divControls.start(keyframe);
    }
  };

  useEffect(() => {
    divControls.start(defaultVariant);
  }, [defaultVariant]);

  return { animate, controls: divControls };
};

export default useSimultaneousAnimation;
