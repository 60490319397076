import React from "react";

type GlobalMetricsContextType = {
  screenHeight: number;
  screenWidth: number;
  bezel: number;
  innerRadius: number;
  outerRadius: number;
  statusBarFontSize: number;
  statusBarInnerHeight: number;
  statusBarXPadding: number;
  statusBarYPadding: number;
  statusBarTotalHeight: number;
  dynamicIslandHeight: number;
  phoneWrapperHeight: number;
  homeScreenGap: number;
  homeScreenIconSize: number;
};

const GlobalMetricsContext = React.createContext<
  GlobalMetricsContextType | undefined
>(undefined);

export const GlobalMetricsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const screenHeight = 500;
  const screenWidth = 500;
  const bezel = screenWidth * 0.02;
  const innerRadius = screenWidth * 0.15;
  const outerRadius = innerRadius + bezel;
  const statusBarFontSize = screenWidth * 0.045;
  const phoneWrapperHeight = screenHeight + bezel * 4;
  const statusBarInnerHeight = statusBarFontSize * 1.5;
  const statusBarXPadding = bezel * 4;
  const statusBarYPadding = bezel * 2;
  const statusBarTotalHeight = statusBarInnerHeight + statusBarYPadding * 2;
  const dynamicIslandHeight = statusBarFontSize * 1.7;

  const homeScreenGap = (screenWidth * 0.1) / (3 + 4);
  const homeScreenIconSize = (screenWidth * 0.9) / 4;

  return (
    <GlobalMetricsContext.Provider
      value={{
        screenHeight,
        screenWidth,
        bezel,
        innerRadius,
        outerRadius,
        statusBarFontSize,
        statusBarInnerHeight,
        statusBarXPadding,
        statusBarYPadding,
        statusBarTotalHeight,
        dynamicIslandHeight,
        phoneWrapperHeight,
        homeScreenIconSize,
        homeScreenGap,
      }}
    >
      {children}
    </GlobalMetricsContext.Provider>
  );
};

export const useGlobalMetrics = () => {
  const context = React.useContext(GlobalMetricsContext);
  if (context === undefined) {
    throw new Error(
      "useGlobalMetrics must be used within a GlobalMetricsProvider"
    );
  }
  return context;
};
