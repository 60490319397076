import React from "react";
import Phone from "./Phone";
import { GlobalMetricsProvider } from "./contexts/GlobalMetrics";
import { IslandStateProvider } from "./contexts/IslandState";
import { motion } from "framer-motion";
import { TimerProvider } from "./contexts/Timer";
import { CallProvider } from "./contexts/Call";

const App = () => {
  return (
    <GlobalMetricsProvider>
      <IslandStateProvider>
        <TimerProvider>
          <CallProvider>
            <div className="min-h-screen w-full flex flex-col items-center justify-center">
              <div>
                <motion.h1
                  className="font-bold text-6xl py-1 bg-gradient-to-b from-black to-gray-400 text-transparent bg-clip-text"
                  initial={{ y: 50, opacity: 0, filter: "blur(10px)" }}
                  animate={{ y: 0, opacity: 1, filter: "blur(0px)" }}
                  transition={{ duration: 1 }}
                >
                  Dynamic Island
                </motion.h1>
              </div>
              <div className="relative p-4">
                <Phone />
              </div>
            </div>
          </CallProvider>
        </TimerProvider>
      </IslandStateProvider>
    </GlobalMetricsProvider>
  );
};

export default App;
