import React from "react";
import { useGlobalMetrics } from "../contexts/GlobalMetrics";
import BatteryIndicator from "./BatteryIndicator";
import DynamicIsland from "./DynamicIsland";
import { AnimatePresence, motion } from "framer-motion";
import { IslandVariant, useIslandState } from "../contexts/IslandState";
import useCall from "../contexts/Call";

const StatusBar = () => {
  const {
    screenWidth,
    bezel,
    outerRadius,
    statusBarFontSize,
    statusBarXPadding,
    statusBarYPadding,
  } = useGlobalMetrics();
  const { islandVariant } = useIslandState();
  const { callState } = useCall();

  const timeAnimationVariants: Record<IslandVariant, { [key: string]: any }> = {
    idle: {
      opacity: 1,
      filter: "blur(0px)",
      translateX: "0px",
    },
    max: {
      opacity: 0,
      filter: `blur(${statusBarFontSize * 0.5}px)`,
      translateX: `${-statusBarFontSize * 1}px`,
    },
    min_single: {
      opacity: 1,
      translateX: `${-statusBarFontSize * 0.5}px`,
    },
    min_dual: {
      opacity: 1,
      translateX: `${-statusBarFontSize * 0.5}px`,
    },
  };
  const rightStatusBarAnimationVariants: Record<
    IslandVariant,
    { [key: string]: any }
  > = {
    idle: {
      opacity: 1,
      filter: "blur(0px)",
      translateX: "0px",
    },
    max: {
      opacity: 0,
      filter: `blur(${statusBarFontSize * 0.5}px)`,
      translateX: `${statusBarFontSize * 1.2}px`,
    },
    min_single: {
      opacity: 1,
      filter: "blur(0px)",
      translateX: "0px",
    },
    min_dual: {
      opacity: 1,
      filter: "blur(0px)",
      translateX: `${statusBarFontSize * 0.5}px`,
    },
  };
  return (
    <div className="w-full">
      <div
        className="flex justify-between items-center relative"
        style={{
          padding: `${statusBarYPadding}px ${statusBarXPadding}px`,
        }}
      >
        <motion.div
          className="text-white text-center font-bold"
          style={{
            fontSize: `${statusBarFontSize}px`,
            padding: `0px ${statusBarFontSize * 0.8}px`,
          }}
          initial={timeAnimationVariants["idle"]}
          animate={timeAnimationVariants[islandVariant]}
          transition={{
            type: "spring",
            damping: 13.2,
            stiffness: 130,
          }}
        >
          {new Date().toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "2-digit",
            hour12: false,
          })}
        </motion.div>
        <AnimatePresence>
          <DynamicIsland />
        </AnimatePresence>
        <motion.div
          className="text-white font-bold flex items-center"
          style={{
            fontSize: `${statusBarFontSize}px`,
            gap: `${statusBarFontSize * 0.4}px`,
          }}
          initial={rightStatusBarAnimationVariants["idle"]}
          animate={rightStatusBarAnimationVariants[islandVariant]}
          transition={{
            type: "spring",
            damping: 13.2,
            stiffness: 130,
          }}
        >
          <motion.img
            src="/icons/Network.svg"
            style={{ height: `${statusBarFontSize * 0.9}px` }}
            initial={{
              opacity: 1,
              filter: "blur(0px)",
              x: 0,
            }}
            transition={{
              type: "spring",
              damping: 13.2,
              stiffness: 130,
            }}
            {...(islandVariant === "min_dual"
              ? {
                  animate: {
                    opacity: 0,
                    filter: `blur(${statusBarFontSize * 0.2}px)`,
                    x: `${statusBarFontSize}px`,
                  },
                }
              : {})}
          />
          <img
            src="/icons/Wifi.svg"
            style={{ height: `${statusBarFontSize * 0.9}px` }}
          />
          <BatteryIndicator />
        </motion.div>
      </div>
    </div>
  );
};

export default StatusBar;
