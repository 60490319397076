import React, { useState } from "react";
import { useGlobalMetrics } from "../contexts/GlobalMetrics";
import { Circle, CircleCheck } from "lucide-react";
import { AnimatePresence, motion } from "framer-motion";
import { useIslandState } from "../contexts/IslandState";
import useCall from "../contexts/Call";

const WidgetOption = ({
  text,
  description,
  onClick,
}: {
  text: string;
  description: string;
  onClick?: () => void;
}) => {
  const { homeScreenGap, innerRadius } = useGlobalMetrics();

  const [checked, setChecked] = useState(false);
  const checkerAnimationVariants = {
    initial: {
      scale: 0,
      opacity: 0,
      filter: `blur(${homeScreenGap * 0.75}px)`,
    },
    finished: {
      scale: 0.99,
      opacity: 1,
      filter: `blur(0px)`,
    },
  };

  return (
    <li
      className="w-full flex items-center hover:bg-gray-50 active:bg-gray-100 transition-colors"
      onClick={() => {
        setChecked((prev) => !prev);
        onClick?.();
      }}
      style={{
        padding: `${innerRadius * 0.12}px ${innerRadius * 0.16}px`,
        gap: `${innerRadius * 0.12}px`,
      }}
    >
      <div
        className="relative origin-center"
        style={{
          marginLeft: `${homeScreenGap * 1}px`,
          width: `${homeScreenGap * 4}px`,
          height: `${homeScreenGap * 4}px`,
        }}
      >
        <AnimatePresence>
          {checked ? (
            <motion.div
              className="absolute inset-0 flex items-center justify-center"
              initial={checkerAnimationVariants["initial"]}
              animate={checkerAnimationVariants["finished"]}
              exit={checkerAnimationVariants["initial"]}
              transition={{
                duration: 0.3,
              }}
              key={"checked-state"}
            >
              <CircleCheck size={homeScreenGap * 3} />
            </motion.div>
          ) : (
            <motion.div
              className="absolute inset-0 flex items-center justify-center"
              initial={checkerAnimationVariants["initial"]}
              animate={checkerAnimationVariants["finished"]}
              exit={checkerAnimationVariants["initial"]}
              transition={{
                duration: 0.3,
              }}
              key={"unchecked-state"}
            >
              <Circle size={homeScreenGap * 3} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div
        className="w-full flex flex-col"
        style={{ marginLeft: `${homeScreenGap * 1}px` }}
      >
        <span
          className="font-medium"
          style={{
            fontSize: `${homeScreenGap * 2.75}px`,
          }}
        >
          {text}
        </span>
        <span
          className="text-gray-600 leading-[1.1]"
          style={{
            fontSize: `${homeScreenGap * 2.25}px`,
            paddingTop: `${homeScreenGap * 0.5}px`,
          }}
        >
          {description}
        </span>
      </div>
    </li>
  );
};

const Widget = () => {
  const { homeScreenIconSize, homeScreenGap, innerRadius } = useGlobalMetrics();
  const widgetSize = homeScreenIconSize * 2 + homeScreenGap;

  const { islandState, setIslandState } = useIslandState();
  const { callState, setCallState } = useCall();
  return (
    <motion.div
      className="flex flex-col items-center justify-center"
      style={{
        width: `${widgetSize}px`,
        gap: `${homeScreenGap * 0.3}px`,
      }}
      initial={{
        opacity: 0,
        y: widgetSize / 2,
        scale: 0.9,
        filter: `blur(${homeScreenGap}px)`,
      }}
      animate={{ opacity: 1, y: 0, scale: 1, filter: "blur(0px)" }}
      transition={{
        duration: 0.8,
        delay: 0.6,
      }}
    >
      <div
        className="bg-white overflow-hidden"
        style={{
          height: `${widgetSize}px`,
          width: `${widgetSize}px`,
          borderRadius: `${innerRadius * 0.4}px`,
          boxShadow: `0 ${homeScreenGap * 0.5}px ${
            homeScreenGap * 0.5
          }px rgb(0 0 0 / 0.2)`,
        }}
      >
        <ul className="w-full flex flex-col items-center">
          <WidgetOption
            text="Call"
            description="Get a call from Tim Cook"
            onClick={() => {
              setIslandState("call");
              if (callState === "idle") {
                setCallState("incoming");
              }
            }}
          />
          <hr
            className="w-[90%] border-t-black/15"
            style={{
              borderTopWidth: `${homeScreenGap * 0.25}px`,
            }}
          />
          <WidgetOption
            text="Timer"
            description="Start a timer"
            onClick={() => setIslandState("timer")}
          />
        </ul>
      </div>
      <span
        className="text-white font-medium"
        style={{
          fontSize: `${homeScreenGap * 2.325}px`,
          textShadow: `0 0 ${homeScreenGap * 0.75}px rgb(0 0 0 / 0.8)`,
        }}
      >
        Island Options
      </span>
    </motion.div>
  );
};

export default Widget;
